import React, { useRef, useState, useEffect } from "react";
import FontIcon from "react-md/lib/FontIcons";
import { Button } from "react-md";
import { Row, Col } from "react-flexbox-grid";
import "../styles/assignedServiceProvider.css";
import EventService from "../../../../../service/event/EventService";
import CreateServiceUtil from "../services/CreateServiceUtil";
import TextField from "react-md/lib/TextFields";
import ExpandableTable from "../helper/expandableTable";
import AssignServiceProviderUtil from "../services/AssignServiceProviderUtil";

function AssignedServiceProvider(props) {
  const [serviceData, setServiceData] = useState({});
  const [assignedServiceProvidersData, setAssignedServiceProvidersData] =
    useState([]);
  const [componentMounted, setComponentMounted] = useState(false);
  const [expandableTile, setExpandableTile] = useState(<></>);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [totalCount, setTotalCount] = useState(10);

  const createServiceUtil = new CreateServiceUtil();
  const assignServiceProviderUtil = new AssignServiceProviderUtil();

  useEffect(() => {
    getServiceDataById(props.data.serviceId);
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      getAssignedServiceProvidersDataByFilter({
        pageNum: pageNum,
        pageSize: pageSize,
        serviceIds: [serviceData?.id ? serviceData?.id : 0],
        serviceProviderIds: [],
      });
    }
  }, [serviceData, pageNum, pageSize]);

  useEffect(() => {
    if (componentMounted) {
      console.log("ni- > asp -> ", assignedServiceProvidersData);

      setExpandableTile((prev) => (
        <ExpandableTable
          {...{
            data: {
              service: serviceData,
              assignedServiceProviders: assignedServiceProvidersData,
            },
            setView: props?.setView,
            goToPrevPage: navigateToPrevPage,
            goToNextPage: navigateToNextPage,
            totalCount: totalCount,
            pageNum: pageNum,
            pageSize: pageSize,
          }}
        />
      ));
      // setPropsDataExpandableTile((prev) => (!prev));
    }
  }, [assignedServiceProvidersData]);

  const getServiceDataById = (id) => {
    let onResponse = (res) => {
      console.log("pr- got res for id=", res);
      setServiceData(res);
      // setServiceData((prevData) => ({ ...prevData, ...res }));
      // console.log("ni -> servcie Data: ",serviceData)
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceById(id, onResponse, onError);
  };

  const getAssignedServiceProvidersDataByFilter = (body) => {
    let onResponse = (res) => {
      console.log("pr- got res for getAssignedServiceProvidersByFilter: ", res);
      // setAssignedServiceProvidersData(res?.assignedServiceProviders);
      setTotalCount((prev) => res?.totalCount ?? 0);
      setAssignedServiceProvidersData(
        (prevData) => res?.assignedServiceProviders ?? []
      );
      console.log(
        "ni -> assigned servcie provdier data",
        assignedServiceProvidersData
      );
      // setPropsDataExpandableTile((prev) => (!prev));
      // setData((prev) => ({ ...prev })); // ✅ Forces re-render by creating a new object
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching assigned service providers: ${err?.message}`,
      });
    };
    assignServiceProviderUtil.getAssignedServiceProvidersByFilter(
      body,
      onResponse,
      onError
    );
  };

  const getServiceDetailsSection = () => {
    return (
      <div className="service-details-container">
        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceId"
              name="serviceId"
              label="Service ID"
              type="text"
              required
              // leftIcon={<FontIcon>assignment</FontIcon>}
              disabled
              value={serviceData?.id}
              // error={ this.state.error.description ? true : false }
              // errorText={ this.state.error.description }
              // onChange={ this._syncState }
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="service"
              name="service"
              label="Service"
              type="text"
              required
              disabled
              value={serviceData?.name}
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceName"
              name="serviceName"
              label="Service name"
              type="text"
              required
              disabled
              value={serviceData?.serviceName}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceType"
              name="serviceType"
              label="Service type"
              type="text"
              required
              disabled
              value={serviceData?.serviceTypeName}
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceSubtype"
              name="serviceSubtype"
              label="Service subtype"
              type="text"
              required
              disabled
              value={serviceData?.serviceSubtypeName}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const exitPage = () => {
    setServiceData({});
    props.setView(1);
  };

  const navigateToNextPage = () => {
    if (pageNum * pageSize < totalCount) {
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  };
  const navigateToPrevPage = () => {
    if (pageNum > 1) {
      setPageNum((prevPageNum) => prevPageNum - 1);
    }
  };

  const getAssignedServiceProviders = () => {
    return (
      <ExpandableTable
        {...{
          data: {
            service: serviceData,
            assignedServiceProviders: assignedServiceProvidersData,
          },
          setView: props?.setView,
          goToPrevPage: navigateToPrevPage,
          goToNextPage: navigateToNextPage,
          totalCount: totalCount,
          pageNum: pageNum,
          pageSize: pageSize,
        }}
      />
    );
  };

  return (
    <div>
      <div className="heading-outer-container">
        <div className="heading-inner-container">
          <div
            className="heading-back-arrow"
            onClick={() => {
              exitPage();
            }}
          >
            <FontIcon>arrow_back</FontIcon>
          </div>
          <h1 className="heading-text">Assigned service providers</h1>
        </div>
      </div>
      <div style={{ height: "25px" }}></div>
      {getServiceDetailsSection()}
      <div style={{ height: "60px" }}></div>
      <div className="service-provider-header-div">
        <h3 className="heading-h3">Service providers</h3>
        <div className="service-provider-header-inner-div">
          <Button
            disabled={!(serviceData?.serviceTypeId == 2)}
            raised
            secondary
            onClick={() => {
              props.setView(6, {
                mode: "assign",
                assignMode: "group",
                service: serviceData,
              });
            }}
          >
            Add group
          </Button>
          <Button
            raised
            secondary
            onClick={() => {
              props.setView(6, {
                mode: "assign",
                service: serviceData,
              });
            }}
          >
            Add
          </Button>
          <Button raised secondary>
            Upload csv
          </Button>
        </div>
      </div>
      <div style={{ height: "16px" }}></div>
      {expandableTile}
      {/* {getAssignedServiceProviders()} */}
    </div>
  );
}

export default AssignedServiceProvider;
