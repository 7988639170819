const statusList = [
  { value: 1, name: "In progress" },
  { value: 2, name: "Completed" },
  { value: 3, name: "Cancelled" },
  { value: 4, name: "Confirmed" },
  { value: 5, name: "Scheduled" },
  { value: 6, name: "Reschedule Requested" },
  { value: 7, name: "Phlebo Assigned" },
  { value: 8, name: "Pickup" },
  { value: 9, name: "Sample Collected" },
  { value: 10, name: "Sample Received" },
  { value: 11, name: "Report Generated" },
  { value: 12, name: "Successful HRA" },
  { value: 13, name: "Successful PRC" },
  { value: 14, name: "Declined" },
  { value: 15, name: "Not eligible" },
  { value: 16, name: "Not answered" },
  { value: 17, name: "Not answered - 3 attempts" },
  { value: 18, name: "Call later" },
  { value: 19, name: "Not reachable" },
  { value: 20, name: "Request to lab" },
  { value: 21, name: "No show" },
  { value: 22, name: "Lab visited" },
];

function getStatusName(statusCode) {
  const status = statusList.find((s) => s.value === statusCode);
  return status ? status.name : "Unknown Status";
}

export default getStatusName;
