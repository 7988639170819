import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/createAppointmentStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavigoDashboardUtils from "../services/NavigoDashboardUtils";
import EventService from "../../../../../service/event/EventService";
import debounce from "debounce";
import { TextField as TextField2 } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import intToTime from "../helper/intToTime";
import getStatusName from "../helper/navigoStatusList";
import PatientsService from "../../../../ops/modules/patients/service/PatientsService";

export const DocumentUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [reasonList, setReasonList] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [appDocMap, setAppDocMap] = useState([]);
  const [token, setToken] = useState("");
  const navigoDashboardUtils = new NavigoDashboardUtils();
  const patientService = new PatientsService();
  const uploadFileRef = useRef(null);

  useEffect(() => {
    fetchAppointment();
    fetchReasonsForConsultation();
    generateDocumentToken();
    getAppointmentDocMapping(props.data.appId);
  }, []);

  useEffect(() => {
    if (appointment != null && reasonList != null) {
      setIsMounted(true);
    }
  }, [appointment, reasonList]);

  const fetchAppointment = () => {
    const onResponse = (res) => {
      if (res.appointments != 0) {
        setAppointment(res.appointments[0]);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.fetchAppointments(
      {
        pageNum: 1,
        pageSize: 1,
        appointmentId: props.data.appId,
      },
      onResponse,
      onError
    );
  };
  const getAppointmentDocMapping = (appId) => {
    const onResponse = (res) => {
      console.log("pr- app doc map=", res);
      if (res != undefined && res.documents.length > 0) {
        setAppDocMap(res.documents);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.getAppointmentDocMapping(appId, onResponse, onError);
  };
  const fetchReasonsForConsultation = () => {
    const onResponse = (res) => {
      console.log("pr- rfc=", res);
      setReasonList(res.length != 0 ? res : []);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.fetchReasonsForConsultation(onResponse, onError);
  };
  const getDocumentFormat = (type) => {
    switch (type) {
      case "jpg":
      case "jpeg":
        return 1;
      case "png":
        return 2;
      case "gif":
        return 3;
      case "pdf":
        return 4;
      case "mp3":
        return 5;
      case "mp4":
        return 6;
      default:
        return 0;
    }
  };
  const handleUploadReportClick = () => {
    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleUploadFile = (e) => {
    if (e.target.files.length === 0) return;
    let document = {
      title: "",
      description: "",
      url: "",
      type: 11,
      format: getDocumentFormat(e.target.files[0].name.split(".").pop()),
      status: 4,
    };
    if (appointment?.bookedFor != 0) {
      let body = {
        ...document,
      };
      document.type = 6;
      navigoDashboardUtils.uploadDocument(
        e.target.files[0],
        e.target.files[0].name,
        body,
        (res) => {
          console.log("pr- doc upload succ=", res);

          navigoDashboardUtils.uploadDocumentDigital(
            {
              appointmentId: appointment?.id,
              documentId: res.id,
            },
            (res2) => {
              console.log("pr- doc mapped succ=", res2);
              getAppointmentDocMapping(appointment?.id);
            },
            (err2) => {
              console.log("pr- error in mapping=", err2);
              EventService.emit("showError", {
                message: `Error: ${err2?.message}`,
              });
            }
          );
          labtestAutomation(res);
        },
        (err) => {
          console.log("pr- err in uploading doc=", err);

          EventService.emit("showError", {
            message: `Error: ${err?.message}`,
          });
        },
        appointment?.bookedFor
      );
    }
    navigoDashboardUtils.uploadDocument(
      e.target.files[0],
      e.target.files[0].name,
      document,
      (res) => {
        console.log("pr- doc upload succ=", res);

        navigoDashboardUtils.uploadDocumentDigital(
          {
            appointmentId: appointment?.id,
            documentId: res.id,
          },
          (res2) => {
            console.log("pr- doc mapped succ=", res2);
            getAppointmentDocMapping(appointment?.id);
          },
          (err2) => {
            console.log("pr- error in mapping=", err2);
            EventService.emit("showError", {
              message: `Error: ${err2?.message}`,
            });
          }
        );
        labtestAutomation(res);
      },
      (err) => {
        console.log("pr- err in uploading doc=", err);

        EventService.emit("showError", {
          message: `Error: ${err?.message}`,
        });
      },
      appointment?.patientID
    );
  };
  const labtestAutomation = (res) => {
    navigoDashboardUtils.uploadDocumentLabtestAutomation(
      {
        patientId: res.patientId,
        documentURL: res.url,
      },
      (res3) => {
        console.log("pr- lab test auto success=", res3);
      },
      (err3) => {
        EventService.emit("showError", {
          message: `Error: ${err3?.message}`,
        });
      }
    );
  };

  const generateDocumentToken = () => {
    patientService.getAndSaveDocumentToken(
      (response) => {
        localStorage.setItem("zy.bridge.doc.token", JSON.stringify(response));
        setToken(response.token);
      },
      (error) => {}
    );
  };
  const viewDocument = (url, token) => {
    if (url) {
      window.open(url + "?token=" + token.toString(), "_blank");
    }
  };

  const exitPage = () => {
    setLoading(false);
    setAppointment(null);
    setReasonList(null);
    setIsMounted(false);
    setAppDocMap([]);
    setToken("");
    props.setView(0);
  };

  return !isMounted ? (
    <></>
  ) : (
    <div className="navigo-module-page-padding">
      {console.log("pr- app=", appointment)}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="navigo-module-view">
            <div className="navigo-module-heading-with-back-button">
              <div
                className="navigo-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>{`Appointment details`}</h2>
            </div>
            <div className="navigo-module-buttons-container">
              <div className="navigo-module-buttons-right-alignment">
                <Button
                  raised
                  disabled={loading}
                  className="navigo-module-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="navigo-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Appointment status`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={getStatusName(appointment?.status)}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Personal details`}</h4>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={
                    appointment?.bookedFor == 0
                      ? appointment?.patientID
                      : appointment?.bookedFor
                  }
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.patientName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Patient phone number`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40px", marginRight: "20px" }}>
                    <TextField
                      value={`+${appointment.phoneNumber.countryCode}`}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <TextField
                      value={`+${appointment.phoneNumber.phoneNo}`}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Appointment details`}</h4>
        {appointment?.serviceTypeID == 1 ? (
          <>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Appointment ID`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.id}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>

              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Patient calling number`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "40px", marginRight: "20px" }}>
                        <TextField
                          value={`+${appointment.customContactNumber.countryCode}`}
                          lineDirection="left"
                          disabled
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <TextField
                          value={`+${appointment.customContactNumber.phoneNo}`}
                          lineDirection="left"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={1} md={1} lg={1}>
                <div style={{ height: "20px" }}></div>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Reason for consultation`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={
                        reasonList.find((e) => e.id == appointment?.reasonId)
                          ?.name
                      }
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Reason description`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.reasonDetails}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : appointment?.serviceTypeID == 2 ? (
          <>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <div className="navigo-module-create-service-field-container">
                  <div className="navigo-module-label">
                    {`Appointment ID`}
                    <label className="navigo-module-label-red"> *</label>
                  </div>
                  <div>
                    <TextField
                      value={appointment?.id}
                      lineDirection="left"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}

        <br />
        {/* 1-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Creation date`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={moment(appointment?.createdAt).format("DD MMM YYYY")}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Appointment date`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={moment(appointment?.appointmentDate).format(
                    "DD MMM YYYY"
                  )}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Time slot`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={intToTime(appointment?.timeSlot)}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 2-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceID}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service type`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceTypeName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service subtype`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceSubtypeName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 3-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service code`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceCode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service mode`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.mode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {/* 4-------- */}
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service provider ID`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceProviderID}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Service provider name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.serviceProviderName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`User account and plan for appointment`}</h4>
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Account code`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.accountCode}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Account name`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={appointment?.accountName}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="navigo-module-create-service-field-container">
              <div className="navigo-module-label">
                {`Plan`}
                <label className="navigo-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={`${appointment?.planID} - ${appointment?.planName}`}
                  lineDirection="left"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <h4 className="navigo-module-sub-heading-margin">{`Documents related to appointment`}</h4>
        {appDocMap.length != 0 && (
          <>
            <Row>
              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Document ID</h4>
              </Col>

              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Update date</h4>
              </Col>

              <Col xs={4} sm={2} md={2} lg={2}>
                <h4>Document name</h4>
              </Col>
            </Row>

            {appDocMap.map((doc) => {
              return (
                <>
                  <Row>
                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{doc.id}</div>
                    </Col>

                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{moment(doc.updatedAt).format("DD MMM YYYY")}</div>
                    </Col>

                    <Col xs={12} sm={2} md={2} lg={2}>
                      <div>{doc.title}</div>
                    </Col>
                    <Col xs={12} sm={2} md={3} lg={3}>
                      <div style={{ height: "20px" }}></div>
                    </Col>
                    <Col xs={12} sm={4} md={3} lg={3}>
                      <Button
                        raised
                        className={"navigo-module-view-doc"}
                        onClick={() => viewDocument(doc.url, token)}
                      >
                        {"View document"}
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </>
              );
            })}
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            raised
            disabled={loading}
            style={{ textAlign: "center" }}
            className={
              loading
                ? "navigo-module-button-save-disabled"
                : "navigo-module-button-save-enabled"
            }
            onClick={() => {
              handleUploadReportClick();
            }}
          >
            {"Upload a new document"}
          </Button>
          <input
            type="file"
            hidden
            accept={"application/pdf,image/png,image/jpeg,image/jpg"}
            ref={uploadFileRef}
            onChange={handleUploadFile}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
