import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";

export class NavigoDashboardUtils {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();
  }

  getAccountsByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.accountDashboard.getAccountsByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  fetchKeyAccountManagers(onResponse, onError) {
    let url = APIConfig.bridgeEmployee.getAll;
    return this.apiService.get(url, onResponse, onError);
  }
  fetchPatientId(id, onResponse, onError) {
    let url = APIConfig.patient.get.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  getAccountsForPid(id, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.getAccountsForPid.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  getPlansForPid(pid, accId, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.getPlansForPid.format(pid);
    return this.apiService.get(url, onResponse, onError);
  }
  getAssignedServicesToPlanByFilter = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.getAssignedServicesToPlanByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getProviderOfPlanService = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.plan.getProviderOfPlanService;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getFamilyMembers = (pid, onResponse, onError) => {
    let url = APIConfig.familyPlan.getFamilyMembers.format(pid);
    return this.apiService.get(url, onResponse, onError);
  };
  getAllRelation = (onResponse, onError) => {
    let url = APIConfig.familyPlan.getAllRelation;
    return this.apiService.get(url, onResponse, onError);
  };
  getAvailableSlots(body, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.getAvailableSlots;
    return this.apiService.post(url, body, onResponse, onError);
  }
  fetchReasonsForConsultation = (onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.reasonsForConsultation;
    return this.apiService.get(url, onResponse, onError);
  };
  createAppointment = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.createAppointment;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getSPLocation = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.location;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getPatientAddress = (pid, onResponse, onError) => {
    let url = APIConfig.familyPlan.primaryAddress.format(pid);
    return this.apiService.get(url, onResponse, onError);
  };
  getSPLocation = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.location;
    return this.apiService.post(url, body, onResponse, onError);
  };
  planServiceProviderPatientFilter = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.planServiceProviderPatientFilter;
    return this.apiService.post(url, body, onResponse, onError);
  };
  fetchAppointments = (body, onResponse, onError) => {
    let url = APIConfig.myPlan.navigo.fetchAppointments;
    return this.apiService.post(url, body, onResponse, onError);
  };
  getServiceProvidersByFilter(body, onResponse, onError) {
    let url =
      APIConfig.myPlan.serviceProviderDashboard.fetchServiceProvidersByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  getServicesByFilter(body, onResponse, onError) {
    let url = APIConfig.myPlan.serviceDashboard.fetchServicesByFilter;
    return this.apiService.post(url, body, onResponse, onError);
  }
  filterByPhone(cc, phone, onResponse, onError) {
    let tenantid = this.authenticationService.getTenantId();
    let url = APIConfig.patient.searchPhoneno4.format(phone, cc, tenantid);
    return this.apiService.get(url, onResponse, onError);
  }
  getAppointmentDocMapping(id, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.getAppointmentDocMapping.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  uploadDocument(fileUrl, fileName, document, onSuccess, onError, pId = null) {
    let documentRequest = new FormData();
    let documentJSON = {};
    // documentJSON.id = document.id;
    documentJSON.patientId = pId;

    documentJSON.title = fileName;
    documentJSON.description = document.description;
    documentJSON.type = document.type;
    documentJSON.format = document.format;
    documentJSON.status = document.status;

    documentRequest.append("file", fileUrl);
    documentRequest.append("fileName", fileName);
    documentRequest.append("document", JSON.stringify(documentJSON));
    documentRequest.append("patientId", pId);

    const url = APIConfig.documentApis.uploadDoc;
    this.apiService.postWithFormData(
      url,
      documentRequest,

      (response) => {
        onSuccess(response);
      },
      (error) => {
        onError(error);
      }
    );
  }
  uploadDocumentDigital(body, onResponse, onError) {
    let url = APIConfig.myPlan.navigo.mapDocToApp;
    return this.apiService.post(url, body, onResponse, onError);
  }
  uploadDocumentLabtestAutomation(body, onResponse, onError) {
    let url = APIConfig.navigoDashboard.uploadDocumentLabtestAutomation;
    return this.apiService.post(url, body, onResponse, onError);
  }
}

export default NavigoDashboardUtils;
