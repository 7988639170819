import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField, Checkbox } from "react-md";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import "../styles/createAccountStyles.css";
import FontIcon from "react-md/lib/FontIcons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AccountDashboardUtils from "../services/AccountDashboardUtils";
import EventService from "../../../../../service/event/EventService";

export const CreateAccount = (props) => {
  const [accountType, setAccountType] = useState(0);
  const [accountIdPrefix, setAccountIdPrefix] = useState("XX");
  const [accountCode, setAccountCode] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountMode, setAccountMode] = useState("");
  const [keyAccountManagers, setKeyAccountManagers] = useState(-1);
  const [keyAccManagersObj, setKeyAccManagersObj] = useState([]);
  const [whitelistedDomains, setWhitelistedEmails] = useState([]);
  const [domainTyped, setDomainTyped] = useState("");
  const [logoViewImage, setLogoViewImage] = useState(null);
  const [logoViewPreview, setLogoViewPreview] = useState("");
  const [mode, setMode] = useState(1);
  const [loading, setLoading] = useState(false);
  const logoViewImageInputRef = useRef(null);

  const accountDashboardUtils = new AccountDashboardUtils();

  useEffect(() => {
    fetchKeyAccountManagers();
    if (props?.data?.mode == "view") {
      setMode(2);
      getAccount(props.data.accountId);
    } else if (props?.data?.mode == "update") {
      setMode(3);
      getAccount(props.data.accountId);
    }
  }, []);

  const exitPage = () => {
    setAccountType(0);
    setAccountIdPrefix("XX");
    setAccountCode("");
    setAccountStatus("");
    setAccountName("");
    setAccountMode("");
    setKeyAccountManagers(-1);
    setKeyAccManagersObj([]);
    setWhitelistedEmails([]);
    setDomainTyped("");
    setLogoViewImage(null);
    setLogoViewPreview("");
    setLoading(false);
    props.setView(0);
  };
  const ACCOUNT_TYPE_OPTIONS = [
    {
      label: "Zyla",
      value: 1,
    },
    {
      label: "Insurance",
      value: 2,
    },
    {
      label: "Pharma",
      value: 3,
    },
    {
      label: "Corporate",
      value: 4,
    },
  ];
  let ACCOUNT_STATUS_OPTIONS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  let ACCOUNT_MODE_OPTIONS = [
    {
      label: "B2B",
      value: "B2B",
    },
    {
      label: "B2C",
      value: "B2C",
    },
  ];
  const handleChangeAccountType = (type) => {
    setAccountType(type);
    if (type == 1) {
      setAccountIdPrefix("01");
    } else if (type == 2) {
      setAccountIdPrefix("02");
    } else if (type == 3) {
      setAccountIdPrefix("03");
    } else if (type == 4) {
      setAccountIdPrefix("04");
    }
  };
  const getAccount = (id) => {
    const onResponse = (res) => {
      let accType = res.accountCode.substring(0, 2);
      if (accType === "01") {
        setAccountType(1);
        setAccountIdPrefix("01");
      } else if (accType === "02") {
        setAccountType(2);
        setAccountIdPrefix("02");
      } else if (accType === "03") {
        setAccountType(3);
        setAccountIdPrefix("03");
      } else if (accType === "04") {
        setAccountType(4);
        setAccountIdPrefix("04");
      } else {
        setAccountType(0);
      }

      setAccountCode(res.accountCode.substring(2, 6));
      setAccountStatus(res.status == true ? "true" : "false");
      setAccountName(res.accountName);
      setAccountMode(res.mode);
      setKeyAccountManagers(res.managerId);
      // setKeyAccManagersObj([]);
      setWhitelistedEmails(res.whitelistedDomains);
      // setDomainTyped("");
      // setLogoViewImage(null);
      setLogoViewPreview(res.logoImage);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    accountDashboardUtils.getAccountsById(id, onResponse, onError);
  };
  const fetchKeyAccountManagers = () => {
    const onResponse = (res) => {
      let obj = res
        .filter((e) => e.team === "BD" && e.status === 4)
        .map((e) => ({
          label: `${e.firstName} ${e.lastName}`,
          value: e.id,
        }));
      setKeyAccManagersObj(obj);
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    accountDashboardUtils.fetchKeyAccountManagers(onResponse, onError);
  };
  const logoViewUploadClick = () => {
    if (logoViewImageInputRef.current) {
      logoViewImageInputRef.current.click();
    }
  };

  const logoViewImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 430 || this.height !== 140) {
        EventService.emit("showError", {
          message: "Image dimensions must be 430x140 pixels",
        });
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setLogoViewImage(file);
    };
    img.src = URL.createObjectURL(file);
  };

  const renderWhitelistedDomains = () => {
    if (whitelistedDomains.length == 0) {
      return <></>;
    } else {
      return (
        <div className="account-module-domains-container">
          {whitelistedDomains.map((e, index) => {
            return (
              <div className="account-module-domains-box">
                <div className="account-module-font">{`@${e}`}</div>
                <div>
                  <FontIcon
                    style={{
                      color: "#6A5EF5",
                      fontSize: "17px",
                      fontWeight: 700,
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (mode != 2) {
                        let removedDomainObj = whitelistedDomains.filter(
                          (_, i) => i !== index
                        );
                        setWhitelistedEmails(removedDomainObj);
                      }
                    }}
                  >
                    close
                  </FontIcon>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const handleWhitelistedEmailIcon = () => {
    if (domainTyped.trim() != "") {
      setWhitelistedEmails([...whitelistedDomains, domainTyped]);
      setDomainTyped("");
    }
  };
  const isInputValid = () => {
    if (accountType < 1 || accountType > 4) {
      EventService.emit("showError", {
        message: "Please select account type",
      });
      return false;
    } else if (accountCode == "" || accountCode.length != 4) {
      EventService.emit("showError", {
        message: "Please enter account ID of 4 characters",
      });
      return false;
    } else if (accountStatus == "") {
      EventService.emit("showError", {
        message: "Please select account status",
      });
      return false;
    } else if (accountName == "") {
      EventService.emit("showError", {
        message: "Please enter account name",
      });
      return false;
    } else if (accountMode == "") {
      EventService.emit("showError", {
        message: "Please select account mode",
      });
      return false;
    } else if (keyAccountManagers == -1) {
      EventService.emit("showError", {
        message: "Please select Key account manager",
      });
      return false;
    } else if (whitelistedDomains.length == 0) {
      EventService.emit("showError", {
        message: "Please enter whitelist email domain",
      });
      return false;
    } else if (logoViewPreview == null || logoViewPreview == "") {
      EventService.emit("showError", {
        message: "Please select account logo",
      });
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (logoViewImage != null) {
      uploadImage(logoViewImage)
        .then((e) => {
          setLogoViewPreview(e);
        })
        .catch((err) => {
          EventService.emit("showError", {
            message: `Error while uploading the logo image: ${err}`,
          });
        });
    }
  }, [logoViewImage]);
  const handleSave = () => {
    if (isInputValid()) {
      let obj = {
        accountName: accountName,
        accountCode: `${accountIdPrefix}${accountCode}`,
        status: accountStatus == "true" ? true : false,
        mode: accountMode,
        managerId: keyAccountManagers,
        whitelistedDomains: whitelistedDomains,
        logoImage: logoViewPreview,
      };
      if (mode == 3) {
        updateAccount(obj);
      } else {
        saveAccount(obj);
      }
    }
  };
  const saveAccount = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      EventService.emit("showError", {
        message: `Account saved`,
      });
      setLoading(false);
      exitPage();
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    accountDashboardUtils.saveAccount(body, onResponse, onError);
  };
  const updateAccount = (body) => {
    setLoading(true);
    const onResponse = (res) => {
      EventService.emit("showError", {
        message: `Account updated`,
      });
      setLoading(false);
      exitPage();
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    accountDashboardUtils.updateAccount(
      props.data.accountId,
      body,
      onResponse,
      onError
    );
  };

  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file, file.name);

      accountDashboardUtils.uploadImg(
        data,
        (res) => {
          resolve(res.publicUrl);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  return (
    <div className="account-module-page-padding">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="account-module-view">
            <div className="account-module-heading-with-back-button">
              <div
                className="account-module-back-btn"
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon style={{ fontSize: "33px" }}>arrow_back</FontIcon>
              </div>
              <h2>
                {mode == 2
                  ? `View account`
                  : mode == 3
                  ? `Update account`
                  : `Create new account`}
              </h2>
            </div>
            <div className="account-module-buttons-container">
              <div className="account-module-buttons-right-alignment">
                {mode != 2 && (
                  <Button
                    raised
                    disabled={loading}
                    className={
                      loading
                        ? "account-module-button-save-disabled"
                        : "account-module-button-save-enabled"
                    }
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {mode == 3 ? "Update" : "Save"}
                  </Button>
                )}

                <Button
                  raised
                  disabled={loading}
                  className="account-module-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="account-module-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Account type`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select account type"
                  className="account-module-select-field"
                  disabled={mode == 2}
                  menuItems={ACCOUNT_TYPE_OPTIONS}
                  value={accountType}
                  onChange={(e) => {
                    handleChangeAccountType(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Account ID`}{" "}
                <label className="account-module-label-red"> *</label>
              </div>
              <div style={{ display: "flex" }}>
                <div className="account-module-prefix">{accountIdPrefix}</div>
                <TextField
                  value={`${accountCode}`}
                  lineDirection="left"
                  disabled={mode == 2}
                  onChange={(e) => {
                    const inputValue = e.toUpperCase();

                    if (/^[A-Z]{0,4}$/.test(inputValue)) {
                      setAccountCode(inputValue);
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Status`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select"
                  className="account-module-select-field"
                  menuItems={ACCOUNT_STATUS_OPTIONS}
                  disabled={mode == 2}
                  value={accountStatus}
                  onChange={(e) => {
                    setAccountStatus(e);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Account name`}{" "}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <TextField
                  value={accountName}
                  lineDirection="left"
                  disabled={mode == 2}
                  onChange={(e) => {
                    setAccountName(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Account mode`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select account type"
                  // disabled={fieldsEnabled.serviceType == false}
                  className="account-module-select-field"
                  menuItems={ACCOUNT_MODE_OPTIONS}
                  disabled={mode == 2}
                  value={accountMode}
                  onChange={(e) => {
                    setAccountMode(e);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Key account manager`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select"
                  // disabled={fieldsEnabled.isActive == false}
                  className="account-module-select-field"
                  menuItems={keyAccManagersObj}
                  disabled={mode == 2}
                  value={keyAccountManagers}
                  onChange={(e) => {
                    setKeyAccountManagers(e);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Whitelist email domain`}{" "}
                <label className="account-module-label-red"> *</label>
              </div>
              <div style={{ display: "flex" }}>
                <div className="account-module-email-prefix">{`@`}</div>
                <TextField
                  id={"account-module-email-domain"}
                  lineDirection="left"
                  value={domainTyped}
                  disabled={mode == 2}
                  placeholder="Type here"
                  inlineIndicator={
                    <Button
                      icon
                      style={{
                        margin: "-10px",
                        cursor: "pointer",
                      }}
                    >
                      <FontIcon
                        style={{
                          color: "orange",
                          lineHeight: "0",
                          marginBottom: "15px",
                        }}
                        onClick={() => {
                          if (mode != 2) {
                            handleWhitelistedEmailIcon();
                          }
                        }}
                      >
                        arrow_forward
                      </FontIcon>
                    </Button>
                  }
                  onChange={(e) => {
                    setDomainTyped(e);
                  }}
                />
              </div>
              {renderWhitelistedDomains()}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="account-module-create-service-field-container">
              <div className="account-module-label">
                {`Account logo`}
                <label className="account-module-label-red"> *</label>
              </div>
              <div className="account-module-image-requirements">{`(Max file size: 20mb, dimension 430*140px)`}</div>
              <Button
                raised
                disabled={mode == 2}
                className="account-module-button-upload"
                onClick={() => {
                  logoViewUploadClick();
                }}
              >
                {"Upload"}
              </Button>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={logoViewImageInputRef}
                style={{ display: "none" }}
                onChange={logoViewImageSelected}
              />
              <Col xs={12} sm={5} md={4} lg={4}>
                {logoViewPreview && (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px solid black",
                        marginTop: "15px",
                        marginLeft: "-6px",
                      }}
                    >
                      <img
                        src={logoViewPreview}
                        alt="Selected Preview"
                        style={{
                          width: "200px",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  </>
                )}
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateAccount;
