import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/navigoDashboardStyles.scss";
import {
  Select,
  TextField,
  MenuItem,
  Menu,
  IconButton,
  Radio,
  InputAdornment,
} from "@material-ui/core";
import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import goIcon from "../../../../../assets/icons/right_arrow_go_btn.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import NavigoDashboardUtils from "../services/NavigoDashboardUtils";
import Helper from "util/Helper";
import { TextField as TextField2 } from "react-md";
import intToTime from "../helper/intToTime";
import { Col } from "react-flexbox-grid";
import PatientTracking from "../../../../ops/modules/patients/modules/tracking/components/PatientTracking";

function NavigoDashboard(props) {
  const [serviceList, setServiceList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [componentMounted, setComponentMounted] = useState(false);
  const navigoDashboardUtils = new NavigoDashboardUtils();
  const helper = new Helper();
  const [patientId, setPatientId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [filterObj, setFilterObj] = useState({
    pageNum: 1,
    pageSize: 30,
  });
  const [onlyPageChange, setOnlyPageChange] = useState(false);

  const handleClick = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setMenuFor(appointment);
  };

  const [listStatus, setListStatus] = useState(4);
  const [noService, setNoService] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedClientCode, setSelectedClientCode] = useState("All");
  const [totalCount, setTotalCount] = useState(0);
  const [numFrom, setNumFrom] = useState(0);
  const [numTo, setNumTo] = useState(0);

  const [creationDateFrom, setCreationDateFrom] = useState(null);
  const [creationDateTo, setCreationDateTo] = useState(null);
  const [rescheduleDateFrom, setRescheduleDateFrom] = useState(null);
  const [rescheduleDateTo, setRescheduleDateTo] = useState(null);
  const [appointmentDateFrom, setAppointmentDateFrom] = useState(null);
  const [appointmentDateTo, setAppointmentDateTo] = useState(null);

  const [serviceTypeFilterValue, setServiceTypeFilterValue] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFilter, setOpenFilter] = useState(0);
  const [maxDate, setMaxDate] = useState("");
  const skip = useRef(30);
  const [searchPid, setSearchPid] = useState("");
  const [searchAppid, setSearchAppid] = useState("");
  const [searchPhoneCC, setSearchPhoneCC] = useState(91);
  const [searchPhoneNo, setSearchPhoneNo] = useState("");
  const [serviceProviderNameOptions, setServiceProviderNameOptions] = useState(
    []
  );
  const [
    filterServiceProviderNameOptions,
    setFilterServiceProviderNameOptions,
  ] = useState([]);
  const [serviceCodeOptions, setServiceCodeOptions] = useState([]);
  const [filterServiceCodeOptions, setFilterServiceCodeOptions] = useState([]);
  const [accountCodeOptions, setAccountCodeOptions] = useState([]);
  const [filterAccountCodeOptions, setFilterAccountCodeOptions] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogType, setDialogType] = useState(0);
  const [callNotePId, setCallNotePId] = useState(0);

  useEffect(() => {
    handleGetAllServices(1, 0, listStatus, selectedClientCode);
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (componentMounted && onlyPageChange == true) {
      setFilterObj({ ...filterObj, pageNum: pageNum });
    }
  }, [onlyPageChange]);
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  useEffect(() => {
    if (componentMounted) {
      skip.current = 30;
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [filterObj, serviceTypeFilterValue]);

  const handleGetAllServices = (
    type,
    btnType = 0,
    status = 4,
    clientCode = "",
    dateFlag = true
  ) => {
    setLoading(true);
    navigoDashboardUtils.fetchAppointments(
      filterObj,
      (res) => {
        console.log("pr- got res app:", res);

        setOnlyPageChange(false);
        if (res && res.appointments && res.appointments.length > 0) {
          let allList = [];

          allList = res.appointments.map((service) => {
            return { ...service };
          });
          let localNumFrom, localNumTo;
          if (type === 1) {
            localNumFrom = 1;
            localNumTo = Math.min(30, res.resultCount);
          } else if (type === 2) {
            if (btnType === 0) {
              localNumTo = numFrom - 1;
              localNumFrom = Math.max(numFrom - 30, 1);
            } else {
              localNumFrom = numTo + 1;
              localNumTo = Math.min(numTo + 30, res.resultCount);
            }
          } else if (type === 3) {
            localNumFrom = numFrom;
            localNumTo = numTo;
          }
          setServiceList([...allList]);
          setNoService(false);
          setNumFrom(localNumFrom);
          setNumTo(localNumTo);
          setTotalCount(res.totalCount);
          setLoading(false);
        } else {
          setServiceList([]);
          setNoService(true);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };

  const handleAddNewAccount = () => {
    props.setView(1, {
      mode: "create",
    });
  };

  const handleEditAppointment = (ele, opt) => {
    if (opt == 1) {
      props.setView(2, {
        appId: ele.id,
      });
    }
  };

  const handlePrevNextAppoint = (arg) => {
    setOnlyPageChange(true);
    if (arg) {
      setPageNum((prevPage) => prevPage + 1);
    } else {
      if (pageNum > 1) {
        setPageNum((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
      }
    }
  };

  useEffect(() => {
    if (!patientId && !appointmentId && !phoneNumber && componentMounted) {
      handleGetAllServices(1, 0, listStatus, "");
    }
  }, [patientId, appointmentId, phoneNumber]);

  useEffect(() => {
    if (componentMounted) {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        status: statusFilterValue.map((e) => {
          return e.value;
        }),
      });
      setPageNum(1);
    }
  }, [statusFilterValue]);
  //-------------------------------------
  useEffect(() => {
    if (componentMounted) {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        serviceProviderID: filterServiceProviderNameOptions.map((e) => {
          return e.id;
        }),
      });
      setPageNum(1);
    }
  }, [filterServiceProviderNameOptions]);
  useEffect(() => {
    if (componentMounted) {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        serviceID: filterServiceCodeOptions.map((e) => {
          return e.id;
        }),
      });
      setPageNum(1);
    }
  }, [filterServiceCodeOptions]);
  useEffect(() => {
    if (componentMounted) {
      setFilterObj({
        ...filterObj,
        pageNum: 1,
        accountCode: filterAccountCodeOptions.map((e) => {
          return e.accountCode;
        }),
      });
      setPageNum(1);
    }
  }, [filterAccountCodeOptions]);
  //-------------------------------------
  function filterUniqueByServiceProviderName(arr) {
    const seen = new Map();
    return arr.filter((e) => {
      const name =
        e.serviceTypeId == 2
          ? e?.checkupInfo?.identityDetails?.name
          : e.serviceTypeId == 1
          ? e?.consultationInfo?.identityDetails?.name
          : null;

      if (!name || seen.has(name)) return false;

      seen.set(name, true);
      return true;
    });
  }
  function filterUniqueByAccountCode(arr) {
    const seen = new Map();
    return arr.filter(
      (item) => !seen.has(item.accountCode) && seen.set(item.accountCode, true)
    );
  }
  //-------------------------------------
  const handleFetchServiceProviderNameOpt = (str) => {
    navigoDashboardUtils.getServiceProvidersByFilter(
      {
        pageNum: 0,
        pageSize: 0,
        nameMatch: [],
        name: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.providers && res.providers.length > 0) {
          let allList = [];

          allList = res.providers.map((service) => {
            return { ...service };
          });
          allList = filterUniqueByServiceProviderName(allList);
          setServiceProviderNameOptions(allList);
        } else {
          setServiceProviderNameOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve service providers",
        });
      }
    );
  };
  const handleFetchServiceCodeOpt = (str) => {
    navigoDashboardUtils.getServicesByFilter(
      {
        pageNum: 0,
        pageSize: 0,
        nameMatch: [],
        name: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.services && res.services.length > 0) {
          let allList = [];

          allList = res.services.map((service) => {
            return { ...service };
          });
          setServiceCodeOptions(allList);
        } else {
          setServiceCodeOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };
  const handleFetchAccountCodeOpt = (str) => {
    navigoDashboardUtils.getAccountsByFilter(
      {
        pageNum: 0,
        pageSize: 0,
        accountCodeMatch: [],
        accountCode: str,
      },
      (res) => {
        setOnlyPageChange(false);
        if (res && res.accounts && res.accounts.length > 0) {
          let allList = [];

          allList = res.accounts.map((service) => {
            return { ...service };
          });
          allList = filterUniqueByAccountCode(allList);
          setAccountCodeOptions(allList);
        } else {
          setAccountCodeOptions([]);
        }
      },
      (err) => {
        setLoading(false);
        setNoService(true);
        EventService.emit("showError", {
          message: "Cannot retrieve account",
        });
      }
    );
  };
  //-------------------------------------

  const statusList = [
    { value: 1, name: "In progress" },
    { value: 2, name: "Completed" },
    { value: 3, name: "Cancelled" },
    { value: 4, name: "Confirmed" },
    { value: 5, name: "Scheduled" },
    { value: 6, name: "Reschedule Requested" },
    { value: 7, name: "Phlebo Assigned" },
    { value: 8, name: "Pickup" },
    { value: 9, name: "Sample Collected" },
    { value: 10, name: "Sample Received" },
    { value: 11, name: "Report Generated" },
    { value: 12, name: "Successful HRA" },
    { value: 13, name: "Successful PRC" },
    { value: 14, name: "Declined" },
    { value: 15, name: "Not eligible" },
    { value: 16, name: "Not answered" },
    { value: 17, name: "Not answered - 3 attempts" },
    { value: 18, name: "Call later" },
    { value: 19, name: "Not reachable" },
    { value: 20, name: "Request to lab" },
    { value: 21, name: "No show" },
    { value: 22, name: "Lab visited" },
  ];

  function getStatusName(statusCode) {
    const status = statusList.find((s) => s.value === statusCode);
    return status ? status.name : "Unknown Status";
  }
  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "pending-class";
      case 2:
      case 6:
        return "progress-class";
      case 3:
        return "closed-class";
      case 4:
        return "invalid-class";
      case 14:
      case 15:
      case 21:
        return "navigo-appoint-non-select-status-inactive";
      case 12:
      case 13:
        return "navigo-appoint-non-select-status-active";
      default:
        return "invalid-class"; // Default fallback
    }
  };

  const filterByPhone = () => {
    const onResponse = (res) => {
      console.log("pr- got pids=", res);
      if (res.length > 0) {
        setFilterObj({
          ...filterObj,
          pageNum: 1,
          patientId: res[0].id,
        });
        setPageNum(1);
      }
    };
    const onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    navigoDashboardUtils.filterByPhone(
      searchPhoneCC,
      searchPhoneNo,
      onResponse,
      onError
    );
  };
  const handleCloseDialog = (arg) => {
    if (arg) {
      handleGetAllServices(1, 0, listStatus, "");
    }
    setDialog(false);
    setDialogType(0);
  };
  return (
    <div className="navigo-module-dashboard-table-container">
      {console.log("pr- filter=", filterObj)}
      <div className="navigo-appoint-container">
        <div className="navigo-appoint-section-3">
          {/* <div
            className="navigo-appoint-section-1"
            onClick={() => {
              helper.navigateTo(props, "/miscellaneous");
            }}
          >
            <FontIcon>arrow_back</FontIcon>
          </div> */}
          <h1 className="navigo-appoint-section-2">Appointments</h1>
          <div
            className="navigo-module-buttons-container"
            style={{ marginLeft: "20px" }}
          >
            <TextField2
              lineDirection="left"
              style={{ width: "100px" }}
              placeholder="Patient ID"
              type="text"
              value={searchPid}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;
                setSearchPid(value == "" ? "" : Number(value));
                if (value == "") {
                  let removedFilterObj = filterObj;
                  delete removedFilterObj.patientId;
                  setFilterObj({
                    ...removedFilterObj,
                    pageNum: 1,
                  });
                }
              }}
            />
            <div className="navigo-module-buttons-right-alignment">
              <Button
                raised
                className={"navigo-module-button-save-enabled"}
                onClick={() => {
                  if (searchPid != "") {
                    setFilterObj({
                      ...filterObj,
                      pageNum: 1,
                      patientId: searchPid,
                    });
                    setPageNum(1);
                  }
                }}
              >
                {"Search"}
              </Button>
            </div>
            <TextField2
              lineDirection="left"
              style={{ width: "110px", marginLeft: "15px" }}
              placeholder="Appointment ID"
              type="text"
              value={searchAppid}
              onChange={(e) => {
                let value = e.replace(/\D/g, "");
                if (value.length > 8) return;
                setSearchAppid(value == "" ? "" : Number(value));
                if (value == "") {
                  let removedFilterObj = filterObj;
                  delete removedFilterObj.appointmentId;
                  setFilterObj({
                    ...removedFilterObj,
                    pageNum: 1,
                  });
                }
              }}
            />
            <div className="navigo-module-buttons-right-alignment">
              <Button
                raised
                className={"navigo-module-button-save-enabled"}
                onClick={() => {
                  if (searchAppid != "") {
                    setFilterObj({
                      ...filterObj,
                      pageNum: 1,
                      appointmentId: searchAppid,
                    });
                    setPageNum(1);
                  }
                }}
              >
                {"Search"}
              </Button>
            </div>
            <div className="mp-phone-container" style={{ marginLeft: "15px" }}>
              <div style={{ width: "40px", marginRight: "20px" }}>
                <Select
                  value={searchPhoneCC}
                  placeholder="+91"
                  onChange={(event) => setSearchPhoneCC(event.target.value)}
                  className="navigo-country-code-select"
                >
                  <MenuItem value={91}>+91</MenuItem>
                  <MenuItem value={63}>+63</MenuItem>
                </Select>
              </div>
              <div style={{ flex: "1" }}>
                <TextField2
                  id={`appointment-search-phone-no`}
                  type="text"
                  lineDirection="left"
                  style={{ width: "120px" }}
                  placeholder={`Phone no`}
                  value={searchPhoneNo}
                  onChange={(elem) => {
                    let value = elem.replace(/\D/g, "");
                    if (value.length <= 10) {
                      setSearchPhoneNo(value == "" ? "" : Number(value));
                      if (value == "") {
                        let removedFilterObj = filterObj;
                        delete removedFilterObj.patientId;
                        setFilterObj({
                          ...removedFilterObj,
                          pageNum: 1,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="navigo-module-buttons-right-alignment">
              <Button
                raised
                className={"navigo-module-button-save-enabled"}
                onClick={() => {
                  filterByPhone();
                }}
              >
                {"Search"}
              </Button>
            </div>
          </div>

          <div className="navigo-appoint-section-4-bottom-button-container">
            <Button
              raised
              className={"navigo-module-button-save-enabled"}
              onClick={() => {
                props.setView(1, null);
              }}
            >
              {"Add new"}
            </Button>
          </div>
        </div>

        <div className="navigo-appoint-section-5">
          <div className="navigo-appoint-section-5-grid-container">
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Phone no
                <br />
                &nbsp;
              </p>
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Appointment
                <br /> ID
              </p>
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section">
              <p>
                Patient <br /> ID
              </p>
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Patient <br />
                name
              </p>
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Creation <br /> date
              </p>
              <img
                src={
                  filterObj?.createdAt?.from && filterObj?.createdAt?.to
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(1);
                }}
              />
              {openFilter === 1 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <div className="navigo-appointment-date-range-filter ">
                    <p>From</p>
                    <TextField
                      type="date"
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateFrom(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            createdAt: {
                              ...filterObj.createdAt,
                              from: moment(v.target.value)
                                .utc()
                                .startOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateFrom}
                    ></TextField>
                  </div>
                  <div className="navigo-appointment-date-range-filter ">
                    <p>To</p>
                    <TextField
                      type="date"
                      defaultValue={""}
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setCreationDateTo(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            createdAt: {
                              ...filterObj.createdAt,
                              to: moment(v.target.value)
                                .utc()
                                .endOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={creationDateTo}
                    ></TextField>
                  </div>

                  <Button
                    className=""
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.createdAt;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setCreationDateFrom(null);
                      setCreationDateTo(null);
                      setPageNum(1);
                      setOpenFilter(0);
                      setOpenBackdrop(false);
                    }}
                  >
                    <FontIcon className="">close</FontIcon>
                  </Button>
                </div>
              )}
            </div>

            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Reschedule <br />
                date
              </p>
              <img
                src={
                  filterObj?.rescheduleDate?.from &&
                  filterObj?.rescheduleDate?.to
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(2);
                }}
              />
              {openFilter === 2 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <div className="navigo-appointment-date-range-filter ">
                    <p>From</p>
                    <TextField
                      type="date"
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setRescheduleDateFrom(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            rescheduleDate: {
                              ...filterObj.rescheduleDate,
                              from: moment(v.target.value)
                                .utc()
                                .startOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={rescheduleDateFrom}
                    ></TextField>
                  </div>
                  <div className="navigo-appointment-date-range-filter ">
                    <p>To</p>
                    <TextField
                      type="date"
                      defaultValue={""}
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setRescheduleDateTo(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            rescheduleDate: {
                              ...filterObj.rescheduleDate,
                              to: moment(v.target.value)
                                .utc()
                                .endOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      inputProps={{ max: maxDate }}
                      value={rescheduleDateTo}
                    ></TextField>
                  </div>

                  <Button
                    className=""
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.rescheduleDate;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setRescheduleDateFrom(null);
                      setRescheduleDateTo(null);
                      setPageNum(1);
                      setOpenFilter(0);
                      setOpenBackdrop(false);
                    }}
                  >
                    <FontIcon className="">close</FontIcon>
                  </Button>
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Appointment <br /> date
              </p>
              <img
                src={
                  filterObj?.appointmentDate?.from &&
                  filterObj?.appointmentDate?.to
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(3);
                }}
              />
              {openFilter === 3 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                  <div className="navigo-appointment-date-range-filter ">
                    <p>From</p>
                    <TextField
                      type="date"
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setAppointmentDateFrom(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            appointmentDate: {
                              ...filterObj.appointmentDate,
                              from: moment(v.target.value)
                                .utc()
                                .startOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      // inputProps={{ max: maxDate }}
                      value={appointmentDateFrom}
                    ></TextField>
                  </div>
                  <div className="navigo-appointment-date-range-filter ">
                    <p>To</p>
                    <TextField
                      type="date"
                      defaultValue={""}
                      style={{ width: "90%" }}
                      className="dob"
                      id="dob"
                      onChange={(v) => {
                        if (v.target.value) {
                          setAppointmentDateTo(v.target.value);
                          setFilterObj({
                            ...filterObj,
                            pageNum: 1,
                            appointmentDate: {
                              ...filterObj.appointmentDate,
                              to: moment(v.target.value)
                                .utc()
                                .endOf("day")
                                .add(1, "day")
                                .toISOString(),
                            },
                          });
                          setPageNum(1);
                        }
                      }}
                      // inputProps={{ max: maxDate }}
                      value={appointmentDateTo}
                    ></TextField>
                  </div>

                  <Button
                    className=""
                    onClick={() => {
                      let removedFilterObj = filterObj;
                      delete removedFilterObj.appointmentDate;
                      setFilterObj({
                        ...removedFilterObj,
                        pageNum: 1,
                      });
                      setAppointmentDateFrom(null);
                      setAppointmentDateTo(null);
                      setPageNum(1);
                      setOpenFilter(0);
                      setOpenBackdrop(false);
                    }}
                  >
                    <FontIcon className="">close</FontIcon>
                  </Button>
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Time
                <br /> slot
              </p>
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Service <br /> code
              </p>
              <img
                src={
                  filterServiceCodeOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(9);
                }}
              />
              {openFilter === 9 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-city">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="navigo-multiple-limit-service-code"
                    options={serviceCodeOptions}
                    getOptionLabel={(option) => option.name}
                    value={filterServiceCodeOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterServiceCodeOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchServiceCodeOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Service <br /> mode
              </p>
              <img src={funnelIcon} onClick={() => {}} />
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Service provider <br /> name
              </p>
              <img
                src={
                  filterServiceProviderNameOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(7);
                }}
              />
              {openFilter === 7 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-city">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="navigo-multiple-limit-service-provider-name"
                    options={serviceProviderNameOptions}
                    getOptionLabel={(option) => {
                      let res =
                        option.serviceTypeId == 2
                          ? option?.checkupInfo?.identityDetails?.name
                          : option.serviceTypeId == 1
                          ? option?.consultationInfo?.identityDetails?.name
                          : "";
                      if (!res) {
                        res = "";
                      }
                      return res;
                    }}
                    value={filterServiceProviderNameOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterServiceProviderNameOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchServiceProviderNameOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Account <br /> code
              </p>
              <img
                src={
                  filterAccountCodeOptions.length > 0
                    ? filterApplied
                    : funnelIcon
                }
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(8);
                }}
              />
              {openFilter === 8 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-city">
                  <Autocomplete
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    multiple
                    id="navigo-multiple-limit-acc-name"
                    options={accountCodeOptions}
                    getOptionLabel={(option) => option.accountCode}
                    value={filterAccountCodeOptions}
                    onChange={(e, v) => {
                      if (v) {
                        setFilterAccountCodeOptions(v);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e, v) => {
                          handleFetchAccountCodeOpt(e.target.value);
                        }}
                        {...params}
                        placeholder=""
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="navigo-appoint-section-5-grid-container-common-section ">
              <p>
                Status <br /> &nbsp;
              </p>
              <img
                src={statusFilterValue.length > 0 ? filterApplied : funnelIcon}
                onClick={() => {
                  setOpenBackdrop(true);
                  setOpenFilter(4);
                }}
              />
              {openFilter === 4 && (
                <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup-status">
                  <Autocomplete
                    multiple
                    id="navigo-appointment-dialog-client-code-filter"
                    className="appointment-dialog-client-code-filter-class"
                    options={statusList}
                    value={statusFilterValue} //statusFilterValue
                    onChange={(e, v) => {
                      if (v.length > 0) {
                        setStatusFilterValue(v);
                      } else {
                        setStatusFilterValue([]);
                      }
                    }}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    // filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Type status name"}
                        className="autocomplete-text-field-class"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <div className="navigo-appoint-section-6">
          {serviceList && serviceList.length > 0 && (
            <div className="navigo-appoint-bottom-bar">
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(0);
                }}
                disabled={pageNum == 1 || loading}
              >
                <FontIcon className="navigo-count-icon">arrow_drop_up</FontIcon>
              </Button>
              <div className="navigo-appoint-bottom-bar-text">
                Showing&nbsp;
                <span
                  style={{
                    color: "#ef7c01",
                  }}
                >
                  {(pageNum - 1) * pageSize + 1}&nbsp;- &nbsp;
                  {totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount}
                </span>
                <span>&nbsp;of&nbsp;</span>
                <span>{` ${totalCount}`}</span>
              </div>
              <Button
                className="navigo-appoint-bottom-bar-button"
                onClick={() => {
                  handlePrevNextAppoint(1);
                }}
                disabled={
                  loading ||
                  pageNum * pageSize > totalCount ||
                  (totalCount > pageNum * pageSize
                    ? pageNum * pageSize
                    : totalCount) == totalCount
                }
              >
                <FontIcon className="navigo-count-icon">
                  arrow_drop_down
                </FontIcon>
              </Button>
            </div>
          )}
          {loading == false && (
            <div className="navigo-appoint-section-6-list">
              {serviceList &&
                serviceList.map((ele) => {
                  return (
                    <div
                      className="navigo-appoint-section-6-grid-container"
                      key={ele.id}
                    >
                      <div className="general-font-class ">
                        {ele.phoneNumber.phoneNo}
                      </div>
                      <div className="general-font-class">{`${ele.id}`}</div>
                      <div className="general-font-class ">
                        {ele.bookedFor == 0 ? ele.patientID : ele.bookedFor}
                      </div>
                      <div className="general-font-class">
                        {ele.patientName}
                      </div>
                      <div className="general-font-class">
                        {moment(ele.updatedAt).format("DD MMM YYYY")}
                      </div>
                      <div className="general-font-class">{"-"}</div>

                      <div className="general-font-class">
                        {moment(ele.appointmentDate).format("DD MMM YYYY")}
                      </div>
                      <div className="general-font-class">
                        {intToTime(ele.timeSlot)}
                      </div>
                      <div className="general-font-class">
                        {ele.serviceCode}
                      </div>
                      <div className="general-font-class">{ele.mode}</div>
                      <div className="general-font-class">
                        {ele.serviceProviderName}
                      </div>
                      <div className="general-font-class">
                        {ele.accountCode}
                      </div>
                      <div
                        className={`general-font-class status-label  ${getStatusClass(
                          ele.status
                        )}`}
                      >
                        {getStatusName(ele.status)}
                      </div>

                      <IconButton
                        onClick={(e) => handleClick(e, ele)}
                        className="navigo-appoint-section-6-grid-edit-call"
                      >
                        <OptionsIcon />
                      </IconButton>
                    </div>
                  );
                })}
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    handleEditAppointment(menuFor, 1);
                    setAnchorEl(null);
                  }}
                >
                  Appointment details
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setCallNotePId(
                      menuFor.bookedFor == 0
                        ? menuFor.patientID
                        : menuFor.bookedFor
                    );
                    setDialog(true);
                    setDialogType(5);
                    setAnchorEl(null);
                  }}
                >
                  Add callnote
                </MenuItem>
              </Menu>
            </div>
          )}

          {loading && <GlobalSkeletonLoader />}
          {loading === false && noService && (
            <div className="no-appointments-container">
              <p>No appointments found!</p>
            </div>
          )}
        </div>
        {dialog && (
          <div className="navigo-dialog-container">
            {dialogType === 5 && (
              <div className="navigo-call-note">
                <FontIcon
                  className="navigo-appoint-upload-close"
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseDialog}
                >
                  close
                </FontIcon>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <hr />
                  <PatientTracking
                    navigo={true}
                    patientId={callNotePId}
                    fromNewBridge={true}
                  />
                </Col>
              </div>
            )}
          </div>
        )}
        {openBackdrop && (
          <div
            className="navigo-appointment-backdrop-class"
            onClick={() => {
              setOpenFilter(0);
              setOpenBackdrop(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default NavigoDashboard;
