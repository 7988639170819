import React, { useRef, useState, useEffect } from "react";
import FontIcon from "react-md/lib/FontIcons";
import { Row, Col } from "react-flexbox-grid";
import "../styles/assignedServiceProvider.css";
import EventService from "../../../../../service/event/EventService";
import CreateServiceUtil from "../services/CreateServiceUtil";
import TextField from "react-md/lib/TextFields";
import { Button } from "react-md";
import { ExpansionList, ExpansionPanel, SelectField } from "react-md";
import ExpandableTable from "../helper/expandableTable";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import AssignServiceProviderUtil from "../services/AssignServiceProviderUtil";
import AvailabilityCalendar from "../helper/AvailabilityCalendar";

function ServiceProviderActionScreen(props) {
  // const [serviceData, setServiceData] = useState({});
  // const [serviceProviderData, setServiceProviderData] = useState({});
  const [isServiceProviderValidBoolValue, setIsServiceProviderValidBoolValue] =
    useState(false);
  const [serviceTypeData, setServiceTypeData] = useState({});
  const [isExpansionPanelExpanded, setIsExpansionPanelExpanded] = useState({
    firstPanel: false,
    secondPanel: false,
    thirdPanel: false,
  });
  const [assignedServiceProviderData, setAssignedServiceProviderData] =
    useState({
      service: props?.data?.service,
      serviceProvider: props?.data?.serviceProvider,
      serviceProviderAssignmentId: props?.data?.serviceProvider?.id,
      externalServiceId: {
        externalServiceId:
          props?.data?.serviceProvider?.externalServiceId ??
          (props?.data?.service?.serviceTypeId == 2
            ? props?.data?.service?.name
            : null),
        enabled: false,
      },
      isContinueClicked: false,
      masterAvailability: {
        config: {},
        schedule: {
          sunday: [],
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
        },
        unavailableTime: [],
        availableTime: [],
      },
    });

  const reverseConvertServiceModesData = (array) => {
    console.log("ni -> array -> ", array);

    if (!Array.isArray(array) || array.length === 0) {
      return {}; // Return an empty object if input is null, undefined, or empty
    }

    const ans = array.reduce((acc, item) => {
      const { mode, ...rest } = item; // Extract mode as key, rest as value
      acc[mode] = rest;
      return acc;
    }, {});

    console.log("ni -> ans -> ", ans);

    return ans;
  };

  const [
    assignedServiceProviderModesData,
    setAssignedServiceProviderModesData,
  ] = useState({});

  const createServiceUtil = new CreateServiceUtil();
  const assignServiceProviderUtil = new AssignServiceProviderUtil();

  useEffect(() => {
    getServiceTypeDataById(props?.data?.service?.serviceTypeId);
    if (props?.data?.mode != "assign") {
      getServiceProviderDataByFilter(
        props?.data?.serviceProvider?.serviceProviderId
      );
    }
    if (props?.data?.mode == "assign" && props?.data?.assignMode == "group") {
      getAllGroupVendors(true);
    }

    if (props?.data?.mode == "view") {
      setAssignedServiceProviderData((prev) => ({
        ...prev,
        isContinueClicked: true,
      }));
    }

    console.log("ni-> props in assigned service provider actionscreen ", props);

    setAssignedServiceProviderModesData(
      reverseConvertServiceModesData(props?.data?.serviceProvider?.serviceModes)
    );
    console.log(
      "ni-> iin service provoder action scereen -> assignedServiceProviderModesData: ",
      assignedServiceProviderModesData
    );
  }, []);

  useEffect(() => {
    console.log(
      "ni-> assignedServiceProviderModesData: ",
      assignedServiceProviderModesData
    );
  }, [assignedServiceProviderModesData]);

  const getServiceDataById = (id) => {
    let onResponse = (res) => {
      console.log("ni- got res for id=", res);
      // setServiceData(res);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceById(id, onResponse, onError);
  };

  const stringToInt = (str) => {
    const num = parseInt(str, 10); // Convert string to integer with base 10
    return isNaN(num) ? null : num; // Check if conversion failed
  };

  const setAvailability = (newAvailability, mode) => {
    updateAssignedServiceProviderModesDataField(
      mode,
      "availability",
      newAvailability
    );
    console.log("ni-> newAvailability -> ", newAvailability);
  };

  const EMPTY_CALENDAR = {
    config: {},
    schedule: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
    unavailableTime: [],
    availableTime: [],
  };

  const updateCalendarAvailabilityConfig = (newValue, updateField, mode) => {
    const { config, ...rest } = assignedServiceProviderModesData?.[mode]
      ?.availability ?? {
      config: {},
      schedule: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
      unavailableTime: [],
      availableTime: [],
    };
    const { [updateField]: val, ...restFields } = config ?? {};
    console.log(
      "ni -> updateCalendarAvailabilityConfig",
      config,
      rest,
      val,
      restFields,
      newValue,
      updateField,
      {
        config: {
          [updateField]: newValue,
          ...restFields,
        },
        ...rest,
      }
    );
    updateAssignedServiceProviderModesDataField(mode, "availability", {
      config: {
        [updateField]: newValue,
        ...restFields,
      },
      ...rest,
    });
  };

  const checkServiceProviderAssignedToService = (
    serviceId,
    serviceProvider
  ) => {
    let onResponse = (res) => {
      console.log("ni- got getAssignedServiceProviderForService res =", res);
      EventService.emit("showError", {
        message: `Service provider (${serviceProvider?.id}) - is already assigned to this service.`,
      });
    };
    let onError = (err) => {
      console.log("ni-> err", err);
      if (err?.code == 404) {
        // not assigned already .. can proceed
        setAssignedServiceProviderData((prev) => ({
          ...prev,
          serviceProvider: serviceProvider,
        }));
        isServiceProviderValid(
          assignedServiceProviderData?.service,
          serviceProvider
        );
        return;
      }
      EventService.emit("showError", {
        message: `Error in checking the service provider assignment status.`,
      });
    };
    assignServiceProviderUtil.getAssignedServiceProviderForService(
      serviceId,
      serviceProvider?.id,
      onResponse,
      onError
    );
  };

  const getServiceProviderDataByFilter = (id) => {
    let onResponse = (res) => {
      console.log("ni- got servProv res for id=", res);
      if (!res?.providers?.[0]) {
        EventService.emit("showError", {
          message: `No active service provider found with id - ${id?.toString()}`,
        });
        return;
      }
      // check if this service provider is already assigned to this service or not??
      if (props?.data?.mode != "assign") {
        setAssignedServiceProviderData((prev) => ({
          ...prev,
          serviceProvider: res?.providers?.[0],
        }));
        isServiceProviderValid(
          assignedServiceProviderData?.service,
          res?.providers?.[0]
        );
      } else {
        checkServiceProviderAssignedToService(
          assignedServiceProviderData?.service?.id,
          res?.providers?.[0]
        );
      }
      // setting calendar schedule availability acc. to this service provider
      if (res?.providers?.[0]?.serviceTypeName == "Consultation") {
        setAssignedServiceProviderData((prev) => ({
          ...prev,
          masterAvailability:
            res?.providers?.[0]?.consultationInfo?.availability,
        }));
      } else {
        setAssignedServiceProviderData((prev) => ({
          ...prev,
          masterAvailability: res?.providers?.[0]?.checkupInfo?.availability,
        }));
      }
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service provider details: ${err?.message}`,
      });
    };
    assignServiceProviderUtil.getServiceProviderByFilter(
      {
        pageNum: 1,
        pageSize: 1,
        status: "active",
        Ids: [stringToInt(id)],
      },
      onResponse,
      onError
    );
  };

  const getAllGroupVendors = (status) => {
    let onResponse = (res) => {
      console.log("ni- got getAllGroupVendors res =", res);
      // setServiceProviderData(res);
      setAssignedServiceProviderData((prev) => ({
        ...prev,
        groupVendors: res,
      }));
      // isServiceProviderValid(assignedServiceProviderData?.service, res);
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service provider details: ${err?.message}`,
      });
    };
    assignServiceProviderUtil.getAllGroupVendors(status, onResponse, onError);
  };

  const getServiceTypeDataById = (id) => {
    let onResponse = (res) => {
      console.log("ni- got servType res for id=", res);
      setServiceTypeData(res);
      // setAssignedServiceProviderData({service: assignedServiceProviderData?.service, serviceProvider: res});
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in fetching the service provider details: ${err?.message}`,
      });
    };
    assignServiceProviderUtil.getServiceTypeById(id, onResponse, onError);
  };

  const assignServiceProvider = (body) => {
    let onResponse = (res) => {
      console.log("ni- got assignServProv res for id=", res);
      if (props?.data?.mode == "update") {
        EventService.emit("showError", {
          message: `Successfully updated the service provider assignment.`,
        });
        exitPage();
      }
      if (props?.data?.mode == "assign") {
        EventService.emit("showError", {
          message: `Successfully assigned the service provider.`,
        });
        exitPage();
      }
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error in assigning the service provider: ${err?.message}`,
      });
    };
    if (props?.data?.mode == "view") {
      EventService.emit("showError", {
        message: `You are currently in view mode. Cannot update details.`,
      });
    }
    if (
      props?.data?.mode == "update" &&
      isFormValid(
        assignedServiceProviderData?.service,
        assignedServiceProviderData?.serviceProvider
      )
    ) {
      assignServiceProviderUtil.updateAssignedServiceProvider(
        assignedServiceProviderData?.serviceProviderAssignmentId,
        body,
        onResponse,
        onError
      );
    }
    if (
      props?.data?.mode == "assign" &&
      props?.data?.assignMode == "group" &&
      isFormValid(
        assignedServiceProviderData?.service,
        assignedServiceProviderData?.serviceProvider
      )
    ) {
      assignServiceProviderUtil.assignServiceProviderByGroupVendorId(
        body,
        onResponse,
        onError
      );
    } else if (
      props?.data?.mode == "assign" &&
      isFormValid(
        assignedServiceProviderData?.service,
        assignedServiceProviderData?.serviceProvider
      )
    ) {
      assignServiceProviderUtil.assignServiceProvider(
        body,
        onResponse,
        onError
      );
    }
  };

  const convertServiceModesData = (data) => {
    // console.log("ni -> convertServiceModesData fxn: ", data);
    if (!data || Object.keys(data).length === 0) {
      return []; // Return an empty array if data is null, undefined, or empty
    }

    // console.log("ni -> convertServiceModesData fxn: ", data);

    return Object.entries(data).map(([key, value]) => ({
      mode: key,
      ...value,
    }));
  };

  const prepareBodyForAssignServiceProvider = () => {
    if (props?.data?.mode == "assign" && props?.data?.assignMode == "group") {
      return {
        serviceId: assignedServiceProviderData?.service?.id,
        providerGroupId: assignedServiceProviderData?.selectedGroupVendor?.id,
        isActive: assignedServiceProviderData?.serviceProvider?.isActive,
        externalServiceId:
          assignedServiceProviderData?.externalServiceId?.externalServiceId,
        serviceModes: convertServiceModesData(assignedServiceProviderModesData),
      };
    }
    return {
      serviceId: assignedServiceProviderData?.service?.id,
      serviceProviderId: assignedServiceProviderData?.serviceProvider?.id,
      isActive: assignedServiceProviderData?.serviceProvider?.isActive,
      externalServiceId:
        assignedServiceProviderData?.externalServiceId?.externalServiceId,
      serviceModes: convertServiceModesData(assignedServiceProviderModesData),
    };
  };

  let isFormValid = (service, serviceProvider) => {
    let isServiceProviderValidBool = isServiceProviderValid(
      service,
      serviceProvider
    );
    if (!isServiceProviderValidBool) {
      return false;
    } else {
      if (props?.data?.mode != "assign") {
        const assignId =
          assignedServiceProviderData?.serviceProviderAssignmentId; // Get the value safely
        if (
          assignId == undefined ||
          assignId == null ||
          assignId.trim() === ""
        ) {
          EventService.emit("showError", {
            message: `Unable to fetch assignment Id.`,
          });
          return false;
        }
      }

      // add required fields as validation targets to validate them.
      let validationTargets = {
        service: ["id"],
        serviceProvider: ["id", "isActive"],
      };
      if (props?.data?.mode == "assign" && props?.data?.assignMode == "group") {
        validationTargets = {}; //no validation is required in service provider details for this case
      }
      const modesRequiredFields = ["price", "modeStatus"];
      const modesConfigRequiredFields = [
        "slotDurationInMinutes",
        "bookingPerSlot",
        "minBookingDays",
        "maxAdvBookingDays",
      ];

      let errorOccurred = false;
      Object.entries(validationTargets).forEach(([key, values]) => {
        values.forEach((val) => {
          if (errorOccurred) return;
          const value = assignedServiceProviderData?.[key]?.[val];
          console.log(`  - ${val} is required in ${key} details: ${value}`);
          if (value == null || value?.toString()?.trim() === "") {
            EventService.emit("showError", {
              message: `${val} is required in ${key} details.`,
            });
            errorOccurred = true;
          }
        });
        // }
      });

      if (errorOccurred) {
        return false;
      }

      errorOccurred = false;
      Object.entries(assignedServiceProviderModesData).forEach(
        ([key, values]) => {
          modesRequiredFields.forEach((e) => {
            if (errorOccurred) return;
            if (assignedServiceProviderModesData?.[key]?.enabled) {
              const value = assignedServiceProviderModesData?.[key]?.[e];
              // console.log(`  - ${val} is required in ${key} details: ${value}`);
              if (value == null || value?.toString()?.trim() === "") {
                EventService.emit("showError", {
                  message: `${e} is required in ${key} mode details.`,
                });
                errorOccurred = true;
              }
            }
          });
        }
      );

      if (errorOccurred) {
        return false;
      }

      errorOccurred = false;
      Object.entries(assignedServiceProviderModesData).forEach(
        ([key, values]) => {
          modesConfigRequiredFields.forEach((e) => {
            if (errorOccurred) return;
            if (assignedServiceProviderModesData?.[key]?.enabled) {
              const value =
                assignedServiceProviderModesData?.[key]?.availability?.config?.[
                  e
                ];
              // console.log(`  - ${val} is required in ${key} details: ${value}`);
              if (value == null || value?.toString()?.trim() === "") {
                EventService.emit("showError", {
                  message: `${e} is required in ${key} mode details.`,
                });
                errorOccurred = true;
              }
            }
          });
        }
      );

      if (errorOccurred) {
        return false;
      }

      // check if any day is enabled in calendar schedule but the values of start and end time is empty
      errorOccurred = false;
      Object.entries(assignedServiceProviderModesData).forEach(
        ([key, values]) => {
          if (assignedServiceProviderModesData?.[key]?.availability?.schedule) {
            Object.entries(
              assignedServiceProviderModesData[key].availability.schedule
            ).forEach(([eKey, eValue]) => {
              if (errorOccurred) return;
              if (assignedServiceProviderModesData?.[key]?.enabled) {
                const values = eValue;
                if (values && values.length > 0) {
                  values.forEach((value) => {
                    if (
                      value?.start == null ||
                      value?.start?.toString()?.trim() === "" ||
                      value?.end == null ||
                      value?.end?.toString()?.trim() === ""
                    ) {
                      EventService.emit("showError", {
                        message: `Please fill start and end time for slot on ${eKey.toString()} OR disable the checkbox for ${eKey.toString()}`,
                      });
                      errorOccurred = true;
                    }
                  });
                }
              }
            });
          }
        }
      );

      if (errorOccurred) {
        return false;
      }

      return true;
    }

    return true;
  };
  const removeKey = (obj, keyToRemove) => {
    const newObj = { ...obj }; // Create a shallow copy to avoid mutating the original object
    delete newObj[keyToRemove]; // Delete the key
    return newObj;
  };

  let isServiceProviderValid = (service, serviceProvider) => {
    console.log("ni -> in isServiceProviderValid -> service: ", service);
    console.log(
      "ni -> in isServiceProviderValid -> service: ",
      serviceProvider
    );

    if (props?.data?.mode == "assign" && props?.data?.assignMode == "group") {
      if (
        assignedServiceProviderData?.selectedGroupVendor?.id &&
        assignedServiceProviderData?.selectedGroupVendor?.name
      ) {
        setIsServiceProviderValidBoolValue((prev) => true);
        return true;
      }
      EventService.emit("showError", {
        message: `Please select a group vendor.`,
      });
      setIsServiceProviderValidBoolValue((prev) => false);
      return false;
    }

    let invalid = false;
    if (service?.serviceTypeId != serviceProvider?.serviceTypeId) {
      invalid = true;
    }
    if (service?.serviceSubtypeId != serviceProvider?.serviceSubtypeId) {
      invalid = true;
    }

    if (invalid) {
      EventService.emit("showError", {
        message: `The service provider's type/subtype does not match with the type/subtype of this service. Please try with some other service provider.`,
      });
    }
    setIsServiceProviderValidBoolValue((prev) => !invalid);
    return !invalid;
  };

  const updateAssignedServiceProviderDataField = (
    parentKey,
    fieldKey,
    newValue
  ) => {
    // setAssignedServiceProviderData({serviceProvider: {id: "176df7y"}})
    console.log("ni -> new value", newValue);
    setAssignedServiceProviderData((prevData) => ({
      ...prevData,
      [parentKey]: {
        ...prevData[parentKey],
        [fieldKey]: newValue,
      },
    }));
  };

  const updateAssignedServiceProviderModesDataField = (
    parentKey,
    fieldKey,
    newValue
  ) => {
    // setAssignedServiceProviderData({serviceProvider: {id: "176df7y"}})
    console.log("ni -> new value", newValue);
    setAssignedServiceProviderModesData((prevData) => ({
      ...prevData,
      [parentKey]: {
        ...prevData[parentKey],
        [fieldKey]: newValue,
      },
    }));
    if (fieldKey === "enabled" && newValue === false) {
      const newObj = removeKey(assignedServiceProviderModesData, parentKey);
      setAssignedServiceProviderModesData(newObj);
    }
  };

  const isServiceProviderValidForConfirm = () => {
    return isFormValid();
  };

  const getServiceProviderDetailsSection = () => {
    return props?.data?.mode == "assign" &&
      props?.data?.assignMode == "group" ? (
      <div className="service-details-container">
        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <SelectField
              id="groupVendor"
              name="groupVendor"
              label="Group vendor"
              required
              menuItems={assignedServiceProviderData?.groupVendors?.map(
                (item) => item?.name
              )}
              fullWidth
              onChange={(e, idx) => {
                updateAssignedServiceProviderDataField(
                  "selectedGroupVendor",
                  "id",
                  assignedServiceProviderData?.groupVendors?.[idx]?.id
                );
                updateAssignedServiceProviderDataField(
                  "selectedGroupVendor",
                  "name",
                  e
                );
              }}
              value={assignedServiceProviderData?.selectedGroupVendor?.name}
              disabled={
                props?.data?.mode == "view" ||
                (assignedServiceProviderData?.isContinueClicked ?? false)
              }
            />
          </Col>
        </Row>
        <Row>
          <Button
            style={{ marginLeft: "8px" }}
            raised
            secondary
            disabled={
              (!isServiceProviderValidBoolValue &&
                !(
                  props?.data?.mode == "assign" &&
                  props?.data?.assignMode == "group"
                )) ||
              (!assignedServiceProviderData?.selectedGroupVendor &&
                props?.data?.mode == "assign" &&
                props?.data?.assignMode == "group") ||
              (assignedServiceProviderData?.isContinueClicked ?? false)
            }
            onClick={() => {
              if (isServiceProviderValidForConfirm()) {
                setAssignedServiceProviderData((prev) => ({
                  ...prev,
                  isContinueClicked: true,
                }));
              }
            }}
          >
            Confirm
          </Button>
        </Row>
      </div>
    ) : (
      <div className="service-details-container">
        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceProviderId"
              name="serviceProviderId"
              label="Service provider ID"
              type="text"
              required
              disabled={
                props?.data?.mode == "view" ||
                (assignedServiceProviderData?.isContinueClicked ?? false)
              }
              value={assignedServiceProviderData?.serviceProvider?.id}
              // error={!assignedServiceProviderData?.serviceProvider?.id}
              // errorText={this.state.error.phoneno}
              onChange={(e) => {
                setAssignedServiceProviderData((prev) => ({
                  ...prev,
                  serviceProvider: null,
                }));
                updateAssignedServiceProviderDataField(
                  "serviceProvider",
                  "id",
                  e
                );
              }}
              inlineIndicator={
                <Button
                  icon
                  className="text-fields__inline-btn"
                  disabled={
                    !assignedServiceProviderData?.serviceProvider?.id ||
                    (assignedServiceProviderData?.isContinueClicked ?? false)
                  }
                  style={{
                    margin: "-10px",
                  }}
                >
                  <FontIcon
                    style={{
                      color: "orange",
                      lineHeight: "0",
                      marginBottom: "15px",
                    }}
                    onClick={() => {
                      // check if this service provider is already assigned to this service And proceed
                      getServiceProviderDataByFilter(
                        assignedServiceProviderData?.serviceProvider?.id
                      );
                    }}
                  >
                    arrow_forward
                  </FontIcon>
                </Button>
              }
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceProviderName"
              name="serviceProviderName"
              label="Service provider name"
              type="text"
              disabled
              value={
                assignedServiceProviderData?.serviceProvider?.serviceTypeId == 2
                  ? assignedServiceProviderData?.serviceProvider?.checkupInfo
                      ?.identityDetails?.name
                  : assignedServiceProviderData?.serviceProvider
                      ?.consultationInfo?.identityDetails?.name ?? ""
              }
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <SelectField
              id="serviceProviderStatus"
              name="serviceProviderStatus"
              label="Service provider status"
              required
              menuItems={["Active", "Inactive"]}
              fullWidth
              onChange={(e) =>
                updateAssignedServiceProviderDataField(
                  "serviceProvider",
                  "isActive",
                  e == "Active" ? true : false
                )
              }
              value={
                assignedServiceProviderData?.serviceProvider?.isActive == true
                  ? "Active"
                  : assignedServiceProviderData?.serviceProvider?.isActive ==
                    false
                  ? "Inactive"
                  : ""
              }
              disabled={
                props?.data?.mode == "view" ||
                (assignedServiceProviderData?.isContinueClicked ?? false)
              }
            />
          </Col>
        </Row>

        <Row>
          {assignedServiceProviderData?.serviceProvider?.checkupInfo
            ?.vendorGroup?.vendorName && (
            <Col xs={12} sm={5} md={4} lg={4}>
              <TextField
                id="groupVendor"
                name="groupVendor"
                label="Group vendor"
                type="text"
                required
                disabled
                value={
                  assignedServiceProviderData?.serviceProvider?.checkupInfo
                    ?.vendorGroup?.vendorName
                }
              />
            </Col>
          )}
          {console.log(
            "ni -> assignedServiceProviderData:  ",
            assignedServiceProviderData
          )}
          {assignedServiceProviderData?.serviceProvider?.serviceTypeId == 2 && (
            <Col xs={12} sm={5} md={4} lg={4}>
              <span>
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={
                    assignedServiceProviderData?.externalServiceId?.enabled ??
                    false
                  }
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    updateAssignedServiceProviderDataField(
                      "externalServiceId",
                      "enabled",
                      e.target.checked
                    );
                    if (
                      e.target.checked == false &&
                      assignedServiceProviderData?.service?.serviceTypeId == 2
                    ) {
                      updateAssignedServiceProviderDataField(
                        "externalServiceId",
                        "externalServiceId",
                        assignedServiceProviderData?.service?.name
                      );
                    }
                  }}
                />
                &nbsp;External service code *
              </span>
              <TextField
                id="externalServiceId"
                name="externalServiceId"
                type="text"
                onChange={(e) =>
                  updateAssignedServiceProviderDataField(
                    "externalServiceId",
                    "externalServiceId",
                    e
                  )
                }
                placeholder="External service code"
                disabled={
                  !(
                    assignedServiceProviderData?.externalServiceId?.enabled ??
                    false
                  ) || props?.data?.mode == "view"
                }
                value={
                  assignedServiceProviderData?.externalServiceId
                    ?.externalServiceId
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          <Button
            style={{ marginLeft: "8px" }}
            raised
            secondary
            disabled={
              !isServiceProviderValidBoolValue ||
              (assignedServiceProviderData?.isContinueClicked ?? false)
            }
            onClick={() => {
              if (isServiceProviderValidForConfirm()) {
                setAssignedServiceProviderData((prev) => ({
                  ...prev,
                  isContinueClicked: true,
                }));
              }
            }}
          >
            Confirm
          </Button>
        </Row>
      </div>
    );
  };

  const getServiceDetailsSection = () => {
    return (
      <div className="service-details-container">
        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceId"
              name="serviceId"
              label="Service ID"
              type="text"
              required
              // leftIcon={<FontIcon>assignment</FontIcon>}
              disabled
              value={assignedServiceProviderData?.service?.id}
              // error={ this.state.error.description ? true : false }
              // errorText={ this.state.error.description }
              // onChange={ this._syncState }
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="service"
              name="service"
              label="Service"
              type="text"
              // required
              disabled
              value={assignedServiceProviderData?.service?.name}
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceName"
              name="serviceName"
              label="Service name"
              type="text"
              // required
              disabled
              value={assignedServiceProviderData?.service?.serviceName}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceType"
              name="serviceType"
              label="Service type"
              type="text"
              // required
              disabled
              value={assignedServiceProviderData?.service?.serviceTypeName}
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={4}>
            <TextField
              id="serviceSubtype"
              name="serviceSubtype"
              label="Service subtype"
              type="text"
              // required
              disabled
              value={assignedServiceProviderData?.service?.serviceSubtypeName}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const toggleExpansionPanelExpandedState = (row, newVal) => {
    setIsExpansionPanelExpanded((prev) => ({
      ...prev,
      [row]: newVal ?? !prev?.[row],
    }));
  };

  const exitPage = () => {
    setServiceTypeData({});
    setAssignedServiceProviderData({});
    setAssignedServiceProviderModesData({});
    props.setView(3, {
      mode: "assign",
      serviceId: props?.data?.service?.id,
    });
  };

  const getServiceModesSection = () => {
    return (
      <ExpansionList>
        {serviceTypeData?.modes?.map((row, index) => (
          <ExpansionPanel
            expanded={isExpansionPanelExpanded?.[row] ?? false}
            onExpandToggle={(isExpanded) => {
              if (isExpanded) {
                if (assignedServiceProviderModesData?.[row]?.enabled)
                  toggleExpansionPanelExpandedState(row?.toString() ?? "");
              } else {
                toggleExpansionPanelExpandedState(row?.toString() ?? "");
              }
            }}
            key={index}
            style={{
              borderRadius: "8px",
              backgroundColor: "#CED4DA",
              marginLeft: "30px",
              marginRight: "30px",
              marginBottom: "20px",
            }}
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ margin: 0, padding: 0 }}
                  checked={assignedServiceProviderModesData?.[row]?.enabled}
                  color="secondary"
                  disabled={props?.data?.mode == "view"}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.checked) {
                      toggleExpansionPanelExpandedState(
                        row?.toString() ?? "",
                        true
                      );
                    } else {
                      toggleExpansionPanelExpandedState(
                        row?.toString() ?? "",
                        false
                      );
                    }
                    updateAssignedServiceProviderModesDataField(
                      row.toString(),
                      "enabled",
                      e.target.checked
                    );
                    if (e.target.checked) {
                      updateAssignedServiceProviderModesDataField(
                        row.toString(),
                        "availability",
                        assignedServiceProviderData?.serviceProvider
                          ?.serviceTypeId == 2
                          ? assignedServiceProviderData?.serviceProvider
                              ?.checkupInfo?.availability ?? EMPTY_CALENDAR
                          : assignedServiceProviderData?.serviceProvider
                              ?.consultationInfo?.availability ??
                              EMPTY_CALENDAR ??
                              EMPTY_CALENDAR
                      );
                    }
                  }}
                />
                <div style={{ width: "10px" }}></div>
                <div style={{ fontWeight: 700 }}>{row ? row : "None"}</div>
              </div>
            }
            expandedSecondaryLabel={null}
            footer={null}
            headerStyle={{
              margin: 0,
              padding: 0,
              marginLeft: 20,
              marginRight: 20,
            }}
            contentClassName="expansion-list-content-class"
            children={
              assignedServiceProviderModesData?.[row]?.enabled && (
                <div
                  style={{
                    backgroundColor: "white",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <Row>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <TextField
                        id="price"
                        name="price"
                        label="Cost price"
                        type="number"
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateAssignedServiceProviderModesDataField(
                            row.toString(),
                            "price",
                            stringToInt(e)
                          )
                        }
                        value={assignedServiceProviderModesData?.[row]?.price}
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <SelectField
                        id="modeStatus"
                        name="modeStatus"
                        label="Mode status"
                        menuItems={["Active", "Inactive"]}
                        fullWidth
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateAssignedServiceProviderModesDataField(
                            row.toString(),
                            "modeStatus",
                            e === "Active" ? true : false
                          )
                        }
                        value={
                          assignedServiceProviderModesData?.[row]
                            ?.modeStatus === true
                            ? "Active"
                            : assignedServiceProviderModesData?.[row]
                                ?.modeStatus === false
                            ? "Inactive"
                            : ""
                        }
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4} />
                  </Row>
                  <Row>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <TextField
                        id="slotDurationInMinutes"
                        name="slotDurationInMinutes"
                        label="Duration (mins)"
                        type="number"
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateCalendarAvailabilityConfig(
                            stringToInt(e),
                            "slotDurationInMinutes",
                            row.toString()
                          )
                        }
                        value={
                          assignedServiceProviderModesData?.[row]?.availability
                            ?.config?.slotDurationInMinutes
                        }
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <TextField
                        id="bookingPerSlot"
                        name="bookingPerSlot"
                        label="Booking per slot"
                        type="number"
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateCalendarAvailabilityConfig(
                            stringToInt(e),
                            "bookingPerSlot",
                            row.toString()
                          )
                        }
                        value={
                          assignedServiceProviderModesData?.[row]?.availability
                            ?.config?.bookingPerSlot
                        }
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4} />
                  </Row>
                  <Row>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <TextField
                        id="minBookingDays"
                        name="minBookingDays"
                        label="Min booking notice (days)"
                        type="number"
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateCalendarAvailabilityConfig(
                            stringToInt(e),
                            "minBookingDays",
                            row.toString()
                          )
                        }
                        value={
                          assignedServiceProviderModesData?.[row]?.availability
                            ?.config?.minBookingDays
                        }
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4}>
                      <TextField
                        id="maxAdvBookingDays"
                        name="maxAdvBookingDays"
                        label="Max advance booking (days)"
                        type="number"
                        required
                        disabled={props?.data?.mode == "view"}
                        onChange={(e) =>
                          updateCalendarAvailabilityConfig(
                            stringToInt(e),
                            "maxAdvBookingDays",
                            row.toString()
                          )
                        }
                        value={
                          assignedServiceProviderModesData?.[row]?.availability
                            ?.config?.maxAdvBookingDays
                        }
                      />
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={4} />
                  </Row>
                  <div style={{ height: "25px" }}></div>
                  <AvailabilityCalendar
                    availability={
                      assignedServiceProviderModesData?.[row]?.availability ?? {
                        config: {},
                        schedule: {
                          sunday: [],
                          monday: [],
                          tuesday: [],
                          wednesday: [],
                          thursday: [],
                          friday: [],
                          saturday: [],
                        },
                        unavailableTime: [],
                        availableTime: [],
                      }
                    }
                    setAvailability={(newAvailability) =>
                      setAvailability(newAvailability, row?.toString())
                    }
                    mode={props?.data?.mode == "view" ? 2 : 1} // if 2 -> view mode , else , edit/create
                    headings={calendarHeadings}
                    slotsValidationSchedule={
                      assignedServiceProviderData?.masterAvailability
                        ?.schedule ?? {
                        sunday: [],
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                      }
                    }
                  />
                </div>
              )
            }
          />
        ))}
      </ExpansionList>
    );
  };

  let calendarHeadings = [
    "Default weekly schedule of the provider",
    "Service provider unavailability beyond default weekly schedule",
    "Service provider availability beyond default weekly schedule",
    "availability beyond default weekly schedule",
  ];

  return (
    <div className="outermost-div">
      <div className="heading-outer-container">
        <div className="heading-inner-container">
          <div
            className="heading-back-arrow"
            onClick={() => {
              exitPage();
            }}
          >
            <FontIcon>arrow_back</FontIcon>
          </div>
          <div className="heading-inner-container-text-and-btns">
            <h1 className="heading-text">
              {props?.data?.mode == "assign"
                ? `Assign new service provider`
                : props?.data?.mode == "update"
                ? `Edit Assigned service provider`
                : `Assigned service provider`}
            </h1>
            {props?.data?.mode != "view" && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  raised
                  secondary
                  onClick={() => {
                    assignServiceProvider(
                      prepareBodyForAssignServiceProvider()
                    );
                  }}
                >
                  Save
                </Button>
                <div style={{ width: "15px" }}></div>
                <Button
                  raised
                  style={{ color: "#333333", backgroundColor: "#EBEBEB" }}
                  onClick={() => {
                    exitPage();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "40px" }}></div>
      {getServiceDetailsSection()}
      <div style={{ height: "60px" }}></div>
      <h3 className="heading-h3">Service provider details</h3>
      <div style={{ height: "16px" }}></div>
      {getServiceProviderDetailsSection()}
      <div style={{ height: "60px" }}></div>
      {(assignedServiceProviderData?.isContinueClicked ?? false) && (
        <>
          <h3 className="heading-h3">Service mode details</h3>
          <div style={{ height: "16px" }}></div>
          {getServiceModesSection()}
        </>
      )}
    </div>
  );
}

export default ServiceProviderActionScreen;
