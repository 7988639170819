const intToTime = (t) => {
  let hours = `${parseInt(t / 60)}`;
  let minutes = `${parseInt(t % 60)}`;

  return `${hours.length === 1 ? `0${hours}` : hours}:${
    minutes.length === 1 ? "00" : minutes
  }`;
};

export default intToTime;
