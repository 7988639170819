import React from "react";
import CreateAccount from "./components/CreateAppointment";
import NavigoDashboard from "./components/NavigoDashboard";
import DocumentUpload from "./components/DocumentUpload";

class NavigoModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      props: null,
    };
  }

  setView = (v, p) => {
    this.setState({ view: v, props: p });
  };

  render() {
    return (
      <>
        {this.state.view == 0 ? (
          <NavigoDashboard setView={this.setView} data={this.state.props} />
        ) : this.state.view == 1 ? (
          <CreateAccount setView={this.setView} data={this.state.props} />
        ) : this.state.view == 2 ? (
          <DocumentUpload setView={this.setView} data={this.state.props} />
        ) : (
          <div>nothing to display</div>
        )}
      </>
    );
  }
}

export default NavigoModule;
